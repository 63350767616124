import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = { initial: Number }
	static targets = [ "input" ]

	//lifecycle


	connect() {
		if (this.hasInitialValue) {
			this.setRating(this.initialValue)
		}
	}

	//actions

	changed(event){
		console.log("changed"+event.target.value)
		Rails.fire(this.element, 'submit');
	}

	//Private Methods

	setRating(value) {
			this.inputTargets.forEach(function(input) {
				if (input.value == value) {
					input.checked = true;
				}
			}
		);
	}
}