import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
	static values = { point: Number, points: Number, size: Number, multiplane: Boolean, zoom:Number, controls: Boolean }
	static targets = [ "render","slottingCheckbox","slowCheckbox" ]

	//lifecycle

	initialize() {
		this.slottingChanged(null);
		this.slowChanged(null);
	}

	connect() {
		this.zoom(1);

		if (this.controlsValue) {
			this.addShortcuts();
		}
	}

	disconnect() {
		if (this.controlsValue) {
			this.removeShortcuts();
		}
	}

	//actions

	previousPoint(event) {
		event.preventDefault();
		this.changePoint(-1);
	}

	nextPoint(event) {
		event.preventDefault();
		this.changePoint(+1);
	}

	slowChanged(event) {
		if (this.hasSlowCheckboxTarget && this.slowCheckboxTarget.checked) {
			this.renderTarget.classList.add("slow");
		}
		else {
			this.renderTarget.classList.remove("slow");
		}
	}

	slottingChanged(event){
		if (this.hasSlottingCheckboxTarget && this.slottingCheckboxTarget.checked) {
			this.renderTarget.classList.add("slotting");
		}
		else {
			this.renderTarget.classList.remove("slotting");
		}
	}

	zoomToFit() {
		this.zoom(1);
	}

	zoomOut() {
		this.zoom(this.zoomValue / 1.1);
	}

	zoomIn() {
		this.zoom(this.zoomValue * 1.1);
	}

	getImage() {
		console.log("getImage");
		event.preventDefault();
		var picUrl = location.protocol + "//" + location.host + location.pathname + ".png?s=1200&p=" + this.pointValue;
		console.log(picUrl);
		location.href = picUrl;
	}

	//Private Methods

	addShortcuts() {
		const controller = this;

		shortcut.add("left",function() {
			controller.changePoint(-1);
		});
		shortcut.add("right",function() {
			controller.changePoint(1);
		});
		shortcut.add("up",function() {
			controller.zoomIn();
		});
		shortcut.add("down",function() {
			controller.zoomOut();
		});
		shortcut.add("escape",function() {
			controller.zoomToFit();
		});
	}

	removeShortcuts() {
		shortcut.remove("left");
		shortcut.remove("right");
		shortcut.remove("up");
		shortcut.remove("down");
		shortcut.remove("escape");
	}

	zoom(zoom) {
		var containerSize = Math.min($(this.renderTarget).parent().width(),$(this.renderTarget).parent().height()) - 20;
		var renderSize = this.sizeValue;
		var scale = (containerSize / renderSize) * zoom;
		this.zoomValue = zoom;
		$(this.renderTarget).css({
			transform: "scale(" + scale + ")"
		});
	}

	changePoint(change) {


		var nextPoint = (this.pointValue + change + this.pointsValue) % this.pointsValue

		var self = this;
		const nextPointFunc = function(){
			console.log("changing");
			self.pointValue = nextPoint;
			self.renderTarget.classList.remove("loopForward");
			self.renderTarget.classList.remove("loopBackward");
		}

		if (nextPoint == 0 && change == 1){
			this.renderTarget.classList.add("loopForward");
			setTimeout(nextPointFunc,100);
		}
		else if (this.pointValue == 0 && change == -1){
			this.renderTarget.classList.add("loopBackward");
			setTimeout(nextPointFunc,100);
		}
		else {
			nextPointFunc();
		}




	}
}